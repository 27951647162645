@import url('https://www.fontspace.com/monkey-snake-font-f19156');

@font-face {
    font-family: "CustomFont";
    src: url("https://yoursite.com/css/fonts/CustomFont.eot");
    src: url("https://yoursite.com/css/fonts/CustomFont.woff") format("woff"),
    url("https://yoursite.com/css/fonts/CustomFont.otf") format("opentype"),
    url("https://yoursite.com/css/fonts/CustomFont.svg#filename") format("svg");
    }

body {
    background-color: #800080 !important;
}

@media screen and (max-width: 991px) {
    .topH1 {
        font-size: 8rem;
    }

    .logo {
        height: 8rem;
    }
}

@media screen and (max-width: 796px) {
    .topH1 {
        font-size: 6rem;
    }

    .logo {
        height: 6rem;
    }
}

/* @media screen and (max-width: 575px) {
    .topH1 {
        font-size: 3vh;
        display: inline-block;
        width: fit-content;
    }

    .logo {
        height: 3vh;
        display: inline-block;
        width: auto;
    }
} */